export enum ProjectLabels {
  WE_KNOW = 'we-know',
  WCI_FOUNDATION = 'wci-foundation',
  WCI_TRANSFORMATION = 'wci-transformation',
}

export enum ProjectModeLabels {
  TEAM = 'team',
  PROFILE = 'profile',
  PROFILE_UNASSIGNED = 'profile-unassigned',
}

export enum ProjectModeGroups {
  TEAM = 'team',
  PROFILE = 'profile',
}

export type ProjectType = {
  prj_id: number;
  prj_name: string;
  prj_label: ProjectLabels;
  prj_use_seasons: boolean;
  prm_label: ProjectModeLabels;
  prm_group: ProjectModeGroups;
  prj_is_switchable: boolean;
};

export type ProjectSeasonPeriod = {
  psp_id: number;
  psp_name: string;
  psp_start: string;
  psp_end: string;
};
